import React from 'react';
import { Typography } from 'antd';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';

import './gallery.scss';

const images: ReactImageGalleryItem[] = [];
const Gallery = () => (
  <>
    <Typography.Title level={3}>Mesmerizing Images</Typography.Title>
    <div className="gallery">
      <ImageGallery
        items={images}
        disableThumbnailScroll
        autoPlay
        slideDuration={300}
      />
    </div>
  </>
);
export default Gallery;
