import React from 'react';
import { Typography } from 'antd';

import './home.scss';

const { Paragraph } = Typography;

const Home = () => (
  <>
    <Typography.Title level={1} className="welcome">
      Welcome
    </Typography.Title>
    <Paragraph>
      We are a software development company dedicated to helping businesses
      thrive in the digital age. With our expertise in web and mobile app
      development, cloud computing, and more, we create innovative solutions
      tailored to your specific needs. Our team of skilled engineers and
      designers work closely with you to deliver custom software that drives
      business success.
    </Paragraph>
    <Paragraph>
      At InnovaStacks, we believe in the power of collaboration. We listen to
      your goals and challenges, leveraging our technical expertise to transform
      your vision into reality. Our agile development approach ensures
      flexibility and adaptability, delivering high-quality results within your
      timeline and budget. With a focus on excellence, we follow industry best
      practices to create robust and reliable software solutions.
    </Paragraph>
    <Paragraph>
      Partnering with InnovaStacks means gaining a competitive edge. We
      understand the unique requirements of various industries, from healthcare
      to finance and e-commerce. Our commitment to your success drives us to go
      above and beyond, providing personalized service and ongoing support. Take
      the first step towards your digital transformation journey with us and
      unlock the full potential of your business.
    </Paragraph>
    <Paragraph>
      Contact InnovaStacks today to discuss your software development needs and
      let us be your trusted partner in driving innovation and growth.
    </Paragraph>
  </>
);

export default Home;
