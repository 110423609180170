import React, { useState } from 'react';
import { Divider, Layout, Space } from 'antd';

import HeaderSection from './components/headerSection/headerSection';
import FooterSection from './components/footerSection/footerSection';

import './template.scss';
import SidebarSection from './components/sidebarSection/sidebarSection';
import {
  getPage,
  homePageKey,
  isBannerRequired,
  isSidebarRequired,
} from './templatepages';
import useDeviceType from './hooks/useDeviceType/useDeviceType';

const { Header, Footer, Content } = Layout;

const Template = () => {
  const [current, setCurrent] = useState(homePageKey);
  const { isScreen } = useDeviceType();

  return (
    <Space
      direction="vertical"
      className={`template ${isScreen ? 'screen' : 'mobile'}`}
    >
      <Layout>
        <Header className="templateheader">
          <HeaderSection current={current} setCurrent={setCurrent} />
          {!isBannerRequired(current) && <Divider className="divider" />}
        </Header>
        {isBannerRequired(current) && <Content className="banner" />}
        <Content>
          <div className="content">
            <div className="page">{getPage(current)}</div>
            {isSidebarRequired(current) && isScreen && (
              <div className="sidebar">
                <SidebarSection />
              </div>
            )}
          </div>
        </Content>
        {!isScreen && (
          <Footer className="footer">
            <FooterSection />
          </Footer>
        )}
        <Footer className="copyright">
          © 2023 Innovastacks. All Rights Reserved.
        </Footer>
      </Layout>
    </Space>
  );
};

export default Template;
