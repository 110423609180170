import React, { useState } from 'react';
import { Layout, Steps, Typography } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import ImageGallery from 'react-image-gallery';
import { bookChapters } from './bookChapters';

import './book.scss';
import useDeviceType from '../../hooks/useDeviceType/useDeviceType';

const Book = () => {
  const [currentChapter, setCurrentChapter] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const pages = bookChapters[currentChapter]?.pages || [];
  const { isScreen } = useDeviceType();
  return (
    <>
      <Typography.Title level={3}>My Book</Typography.Title>
      <Layout>
        <Layout.Sider className="chapters">
          <Steps
            direction="vertical"
            progressDot={isScreen}
            size="small"
            current={currentChapter}
            onChange={(value: number) => {
              setCurrentChapter(value);
              setCurrentPage(0);
            }}
            items={bookChapters.map((chapter) => ({
              title: isScreen ? chapter.title : '',
              description: isScreen ? chapter.description : '',
            }))}
          />
        </Layout.Sider>
        <Layout.Content className="book">
          <div className="bookNav">
            <div className="arrow">
              <LeftOutlined
                onClick={() => {
                  if (currentPage === 0) {
                    const newChapter =
                      currentChapter === 0
                        ? bookChapters.length - 1
                        : currentChapter - 1;
                    const newPage = bookChapters[newChapter].pages.length - 1;
                    setCurrentChapter(newChapter);
                    setCurrentPage(newPage);
                  } else {
                    setCurrentPage((pg) => pg - 1);
                  }
                }}
              />
            </div>
            <div className="booktitle">
              {bookChapters[currentChapter].title}
              <br />
              {bookChapters[currentChapter].description}
              <br />
              <b>
                Page {currentPage + 1} of {pages.length}
              </b>
            </div>
            <div className="arrow">
              <RightOutlined
                onClick={() => {
                  if (currentPage === pages.length - 1) {
                    setCurrentChapter((ch) =>
                      ch >= bookChapters.length - 1 ? 0 : ch + 1
                    );
                    setCurrentPage(0);
                  } else {
                    setCurrentPage((pg) => pg + 1);
                  }
                }}
              />
            </div>
          </div>

          <ImageGallery
            items={pages}
            disableThumbnailScroll
            autoPlay={false}
            showPlayButton={false}
            showThumbnails={false}
            showNav={false}
            startIndex={currentPage}
          />
        </Layout.Content>
      </Layout>
    </>
  );
};

export default Book;
