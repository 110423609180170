import React from 'react';
import { MenuProps, Menu } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import { items } from '../../templatepages';
import './headerSection.scss';

type Props = {
  current: string;
  setCurrent: React.Dispatch<React.SetStateAction<string>>;
};

const HeaderSection: React.FC<Props> = ({ current, setCurrent }: Props) => {
  const onClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'Donate') return;
    setCurrent(e.key);
  };

  const hideme = true;

  return (
    <div className="header">
      <a href="/" rel="noopener noreferrer" className="logo">
        INNOVASTACKS
      </a>
      {!hideme && (
        <Menu
          className="menu"
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={items}
          overflowedIndicator={<MenuOutlined />}
        />
      )}
    </div>
  );
};

export default HeaderSection;
