import React from 'react';
import { Typography } from 'antd';
import './footerSection.scss';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';

const { Paragraph } = Typography;

const FooterSection = () => (
  <>
    <div>
      <Paragraph>
        <Typography.Title level={4}>CONTACT</Typography.Title>
        <Typography.Title level={5}>Call</Typography.Title>
        <p>
          <PhoneOutlined /> +61-473952994
        </p>
        <Typography.Title level={5}>Email</Typography.Title>
        <p>
          <a href="mailto:sameergupta2387@gmail.com" className="emailTo">
            <MailOutlined /> sameergupta2387@gmail.com
          </a>
        </p>
      </Paragraph>
    </div>
    <div>
      <Paragraph>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3317.980232721369!2d150.9437495756828!3d-33.73532727327792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b129f53d93a9dbd%3A0xd300e2162f8e35a8!2s217%2F20B%20Lexington%20Dr%2C%20Bella%20Vista%20NSW%202153!5e0!3m2!1sen!2sau!4v1710932363594!5m2!1sen!2sau"
          className="map"
          allowFullScreen={false}
          loading="lazy"
          referrerPolicy="no-referrer"
          title="InnovaStacks"
        />
      </Paragraph>
    </div>
  </>
);

export default FooterSection;
