import React from 'react';
import { Typography } from 'antd';

import './contact.scss';

const Contact = () => (
  <>
    <Typography.Title level={3}>Contact Us</Typography.Title>
    <div className="contact" />
  </>
);

export default Contact;
